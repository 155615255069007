@import '../../../styles/base';

.textInput {

  label {
    display: block;
    font-size: $font-m;
  }

  input {
    font-size: $font-m;
    border: 1px solid $grey-dark;
    border-radius: 4px;
    padding: 7px;
    width: 260px;

    &:focus {
      border: 1px solid $blue;
      outline: none;
    }
    &[type=number]::-webkit-inner-spin-button, 
    &[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
  }

  margin-bottom: 15px;
}
